<template>
  <!--Program-->
  <section>
    <div class="container" v-if="isReady">
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="box p-4">
            <h2 class="title text-center mb-4">Oturum Kayıtları</h2>
            <div class="stage-picker mb-4">
              <b-button
                size="lg"
                variant="outline-primary"
                @click="pickStage(stages[0])"
                >4 Eylül CUMARTESİ</b-button
              >
              <b-button
                size="lg"
                variant="outline-primary"
                @click="pickStage(stages[1])"
                >5 Eylül PAZAR</b-button
              >
            </div>
            <ul class="program">
              <!--Item-->
              <li
                class="d-flex p-3"
                v-for="session in sessionList"
                :key="session.id"
              >
                <div class="right">
                  <div class="session-time">
                    <img class="time" src="@/assets/images/time.svg" alt="" />
                    {{ session.start | moment("HH:mm") }} -
                    {{ session.end | moment("HH:mm") }}
                    <b-badge
                      variant="danger"
                      v-if="session.isLive"
                      style="
                        color: #fff;
                        display: block;
                        padding: 0.4rem;
                        width: 40px;
                      "
                      >Live</b-badge
                    >
                  </div>
                  <!-- eslint-disable max-len -->

                  <b-button
                    variant="outline-primary"
                    class="play-btn"
                    @click="playClick(session)"
                    v-if="session.vimeoId"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-play-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                      />
                    </svg>
                  </b-button>
                </div>
                <div class="left">
                  <div class="title">{{ session.title }}</div>

                  <span
                    class="description"
                    v-if="session.description"
                    v-html="session.description"
                  ></span>
                  <div
                    class="video"
                    v-if="activeVideoSessionId == session.id"
                    style="width: 100%; margin-top: 16px"
                  >
                    <div
                      class="embed-responsive embed-responsive-16by9"
                      style="width: 100%"
                    >
                      <iframe
                        class="embed-responsive-item"
                        :src="session.vimeoId"
                        width="640"
                        height="564"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <span class="sponsor" v-if="session.sponsorLogo">
                  <img :src="session.sponsorLogo" />
                </span>
              </li>

              <!--Item
              <li class="d-flex mb-3">
                <div>09:00 - 10:00</div>
                <div>
                  <p>Açılış Konuşması</p>
                  <span>Dr. Barış Ökçün</span>
                  <small
                    >İstanbul Üniversitesi Cerrahpaşa Kardiyoloji Enstitüsü -
                    İstanbul</small
                  >
                </div>
              </li>
              -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { stages } from '@/config';

export default {
  name: 'StageList',
  components: {},
  data() {
    return {
      isReady: false,
      selectedStage: null,
      activeVideoSessionId: null,
      stages,
    };
  },
  computed: {
    ...mapGetters({
      sessionList: 'agenda/list',
      loading: 'agenda/loading',
      schema: 'agenda/schema',
      currentEventPath: 'event/currentPath',
      currentEvent: 'event/current',
    }),
    sessionPath() {
      return `${this.currentEventPath}/stage/${this.selectedStage}/session`;
    },
  },
  methods: {
    ...mapActions({
      setCurrent: 'agenda/setCurrent',
      findAgenda: 'agenda/find',
      clearState: 'agenda/clearItems',
    }),
    getAgenda() {
      this.clearState();
      return this.findAgenda({
        path: this.sessionPath,
        subscribe: true,
        order: { field: 'start', desc: false },
      }).then(() => {});
    },
    pickStage(stageId) {
      this.selectedStage = stageId;
      this.getAgenda();
    },
    playClick(session) {
      this.activeVideoSessionId = session.id;
    },
  },
  created() {
    this.selectedStage = this.currentEvent.defaultStage;
    this.getAgenda().then(() => {
      this.isReady = true;
    });
  },
};
</script>

<style lang="scss">
.session-time {
  font-weight: bold;
  font-size: 14px;
  padding-right: 3rem;
  border-right: 2px solid #b60016;

  img.time {
    width: 16px;
    opacity: 0.3;
    margin-top: -3px;
  }
}

.play-btn {
  font-size: 0px !important;
  padding: 4px 32px !important;
  margin-top: 8px;
  width: auto !important;
  svg {
    width: 32px;
    height: 32px;
  }
}

.stage-picker {
  display: flex;
  justify-content: center;
  margin: auto;
  button {
    font-size: 12px;
    font-weight: bold;
    margin-left: 4px;
  }
}
</style>
