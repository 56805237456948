<template>
  <!--Header Banner-->
  <section class="header-banner">
    <img src="@/assets/images/hero.jpeg" />
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>
